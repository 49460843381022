<template>
  <div class="adhesions" v-if="canView">
    <page-header title="Cartes d'adhésions" icon="fas fa-id-card"></page-header>
    <b-row>
      <b-col>
        <b-row class="row-line">
          <b-col cols="4"><b>Nom</b></b-col>
          <b-col cols="2"><b>Type d'adhésion</b></b-col>
          <b-col cols="2"><b>Prix</b></b-col>
          <b-col cols="2"><b>Compte</b></b-col>
          <b-col cols="2" class="text-right">
            <add-adhesion-type-button :school-years="schoolYears" @done="onAdhesionTpeAdded"></add-adhesion-type-button>
          </b-col>
        </b-row>
        <b-row v-for="adhesionType in adhesionTypes" :key="adhesionType.id" class="row-line">
          <b-col cols="4">
            {{ adhesionType.name }}
          </b-col>
          <b-col cols="2">
            {{ adhesionType.getLabel() }}
          </b-col>
          <b-col cols="2">
            <div v-for="elt in adhesionType.prices" :key="elt.id">
              {{ elt.getYear() }}: {{ elt.price | currency }}
            </div>
          </b-col>
          <b-col cols="2">
            <div>
              {{adhesionType.analyticAccount.name}}<br>
              {{adhesionType.generalAccount.name}}
            </div>
          </b-col>
          <b-col cols="2" class="text-right">
            <change-adhesion-type-button :school-years="schoolYears" :id="adhesionType.id" @done="onAdhesionTpeEdited"></change-adhesion-type-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import AddAdhesionTypeButton from '@/components/Adhesions/AddAdhesionTypeButton'
import ChangeAdhesionTypeButton from '@/components/Adhesions/ChangeAdhesionTypeButton'
import PageHeader from '@/components/Layout/PageHeader'
import store from '@/store'
import { BackendApi } from '@/utils/http'
import { makeSchoolYear } from '@/types/schools'
import { makeAdhesionType } from '@/types/adhesions'

export default {
  name: 'adhesion-types',
  mixins: [BackendMixin],
  components: {
    AddAdhesionTypeButton,
    ChangeAdhesionTypeButton,
    PageHeader,
  },
  data() {
    return {
      adhesionTypes: [],
      loadingName: 'adhesions',
      schoolYears: [],
    }
  },
  watch: {
    adhesions: function(newValue, oldValue) {},
    loading: function(newValue, oldValue) {},
  },
  computed: {
    loading() {
      return store.getters.loading
    },
    canView() {
      return this.hasPerm('adhesions.view_adhesiontype')
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      await this.loadSchoolYears()
      await this.loadAdhesionTypes()
    },
    async loadSchoolYears() {
      let url = '/api/active-school-years/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(elt => makeSchoolYear(elt))
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    async loadAdhesionTypes() {
      this.adhesionTypes = []
      let backendApi = new BackendApi('get', '/api/adhesion-types/')
      try {
        let resp = await backendApi.callApi()
        this.adhesionTypes = resp.data.map(elt => makeAdhesionType(elt))
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    onAdhesionTpeAdded(event) {
      this.adhesionTypes.push(event.adhesionType)
    },
    onAdhesionTpeEdited() {
      this.loadSchoolYears()
      this.loadAdhesionTypes()
    },
  },
}
</script>

<style lang="less">
</style>
